import React from "react";
import Image from "next/image";

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ width = 1920, height = 1080 }) => {
    return (
        <Image
            className={`w-${width} h-${height}`}
            src="/pee.png" 
            alt="logo"
            width={150}
            height={150}
        />
    );
};

export default Logo;
